// import store from "@/state/store";

export default [
	{
		path: "/login",
		name: "login",
		component: () => import("@/views/auth/login"),
		meta: {
			title: "Login",
			// beforeResolve(routeTo, routeFrom, next) {
			// 	// If the user is already logged in
			// 	if (store.getters["auth/isLoggedIn"]) {
			// 		// Redirect to the home page instead
			// 		next({ name: "default" });
			// 	} else {
			// 		// Continue to the login page
			// 		next();
			// 	}
			// },
		},
	},
	{
		path: "/logout",
		name: "logout",
		meta: {
			title: "Logout",
			authRequired: true,
			beforeResolve(routeTo, routeFrom, next) {
				localStorage.clear();
				next();
			},
		},
		component: () => import("@/views/auth/logout"),
	},
	{
		path: "/shipping",
		name: "shipping",
		meta: {
			title: "Shipping Dashboard",
			authRequired: true,
		},
		component: () => import("@/views/dashboard/shipping"),
	},
	{
		path: "/preview",
		name: "preview",
		meta: {
			title: "Preview Dashboard",
			authRequired: true,
		},
		component: () => import("@/views/dashboard/preview"),
	},
	{
		path: "/",
		name: "dashboard",
		meta: {
			title: "Preview Dashboard",
			authRequired: true,
		},
		component: () => import("@/views/dashboard/preview"),
	},
	{
		path: "/widgets",
		name: "dashboard-widgets",
		meta: {
			title: "Widgets",
			authRequired: true,
		},
		component: () => import("@/views/widgets"),
	},
	{
		path: "/rarities",
		name: "Rarities",
		meta: { title: "Rarities", authRequired: true },
		component: () => import("@/views/rarities"),
	},
	{
		path: "/staff",
		name: "staff",
		meta: { title: "Staff", authRequired: true },
		component: () => import("@/views/staff"),
	},
	{
		path: "/wallets",
		name: "Wallets",
		meta: { title: "Wallets", authRequired: true },
		component: () => import("@/views/wallets"),
	},
	{
		path: "/nfts",
		name: "NFTs",
		meta: { title: "NFTs", authRequired: true },
		component: () => import("@/views/nfts"),
	},
	{
		path: "/achievements",
		name: "Achievements",
		meta: { title: "Achievements", authRequired: true },
		component: () => import("@/views/coupons"),
	},
	{
		path: "/settings",
		name: "Settings",
		meta: { title: "Configuration", authRequired: true },
		component: () => import("@/views/settings"),
	},
	{
		path: "/profile",
		name: "profile",
		meta: { title: "Profile", authRequired: true },
		component: () => import("@/views/profile"),
	},
	{
		path: "/faqs",
		name: "FAQs",
		meta: { title: "FAQs", authRequired: true },
		component: () => import("@/views/faqs"),
	},
	{
		path: "/error/404",
		name: "404",
		meta: {
			title: "Error 404",
			authRequired: true,
		},
		component: () => import("@/views/errors/404"),
	},
	{
		path: "/error/500",
		name: "500",
		meta: {
			title: "Error 500",
			authRequired: true,
		},
		component: () => import("@/views/errors/500"),
	},
	{
		path: "/auth/ofline",
		name: "ofline",
		meta: {
			title: "Oflone",
			authRequired: true,
		},
		component: () => import("@/views/errors/ofline"),
	},
];
