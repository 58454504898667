export function shorten(string, head = 6, tail = 4) {
	if (string && string.length) {
		const begin = string.slice(0, head);
		const end = string.slice(-tail);
		return `${begin} ... ${end}`;
	}
}

export function currency(amount, symbol = "ETH") {
	const readable = Number(
		(Number(amount) / 10000000000 / 100000000).toFixed(6)
	);
	return `${readable} ${symbol}`;
}

export function fromWei(value, precision = 6) {
	const ethAmount = value / 10000000000 / 100000000;
	return Number(ethAmount.toFixed(precision));
}

export function roundToTwo(val) {
	return +(Math.ceil(val + "e+2") + "e-2");
}

export function deviceType() {
	const ua = navigator.userAgent;
	if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
		return "tablet";
	} else if (
		/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
			ua
		)
	) {
		return "mobile";
	}
	return "desktop";
}

export function environmentNetworks(rpcUrl) {
	return {
		main: {
			rpcUrl,
			name: "mainnet",
			chainId: 1,
			explorer: "https://etherscan.io",
		},
		staging: {
			rpcUrl,
			name: "goerli",
			chainId: 5,
			explorer: "https://goerli.etherscan.io",
		},
		development: {
			name: "private",
			rpcUrl: process.env.VUE_APP_DEV_RPC_URL,
			chainId: process.env.VUE_APP_DEV_CHAIN_ID,
			explorer: process.env.VUE_APP_DEV_EXPLORER_URL,
		},
	};
}

export async function loadContractDetails(contractInstance) {
	if (contractInstance.methods.getSettings) {
		const [
			name,
			symbol,
			signer,
			payoutAddress,
			open,
			stage,
			chainlinkTarget,
			baseURI,
			earnings,
			mintPrice,
			usdPrice,
			holdMax,
			maxSupply,
			mintMax,
			whiteListed,
			totalMinted,
			totalBurned,
			totalSupply,
		] = await contractInstance.methods.getSettings().call();
		return {
			project: "Colton Club",
			name,
			symbol,
			signer,
			payoutAddress,
			open,
			stage,
			chainlinkTarget,
			baseURI,
			earnings: fromWei(earnings),
			mintPrice,
			usdPrice,
			holdMax,
			maxSupply,
			mintMax,
			whiteListed,
			totalMinted: totalMinted,
			totalBurned: Number(totalBurned),
			totalSupply: totalSupply,
		};
	} else {
		return {
			project: "Colton Club",
		};
	}
}

export function compactShippingInfo(shippingRecord) {
  const { wallet, tokenId, shippingInfo: {
      fullname,
      street,
      apt,
      city,
      state,
      zipcode,
      phone,
      email
    }
  } = shippingRecord
  const address = [street, apt].filter(Boolean).join(' ')
  return [fullname, phone, email, address, city, zipcode, state, tokenId, wallet].join(',') + '\n'
}
