export const state = {
  userData: JSON.parse(localStorage.getItem('userdata'))
}

export const mutations = {
  setUserData(state, newValue) {
    state.userData = newValue
    saveState('userdata', newValue)
  },
  clearUserData(state) {
    state.userData = null
  }
}

export const getters = {
  isLoggedIn(state) {
    return !!state.userData
  },
  currentUser(state) {
    return state.userData || {}
  }
}

export const actions = {

}

function saveState(key, state) {
  localStorage.setItem(key, JSON.stringify(state))
}
