<template>
 <router-view></router-view>

</template>

<script>
import store from '@/state/store'
import axios from 'axios'

export default {
  name: 'App',
  created: async function() {
    // Gather configuration
    try {
      const {data: {success, result}} = await axios.get(process.env.VUE_APP_API_URL + '/config')
      if(success) {
        result.apiUrl = process.env.VUE_APP_API_URL
        store.commit('config/setSettings', result)
      }
    } catch(err) {
      console.log(err)
    }
  },
  components: {
  }
}
</script>
