import Contract from "web3-eth-contract";
import { environmentNetworks, loadContractDetails } from "@/lib/utils";

export const state = {
	settings: {},
	contracts: {},
};

export const getters = {
	settings: (state) => state.settings,
	contracts: (state) => state.contracts,
};

export const mutations = {
	setSettings(state, newValue) {
		state.settings = newValue;
	},
	setContracts(state, newValue) {
		state.contracts = newValue;
	},
};

export const actions = {
	async loadContracts({ commit }) {
		console.log(commit);
		if (state.settings && state.settings.contracts) {
			const { environment, alchemyEndpoint, contracts } = state.settings;

			// Get network configuration
			const availableNetworks = environmentNetworks(alchemyEndpoint);
			const modeIsValid = !!availableNetworks[environment];
			const network = modeIsValid
				? availableNetworks[environment]
				: availableNetworks["main"];

			// Target network defined in environment
			Contract.setProvider(network.rpcUrl);

			const resolver = [];
			const contractNames = [];
			for (const name in contracts) {
				contractNames.push(name);
				const { address, abi } = contracts[name];
				const instance = new Contract(abi, address);
				resolver.push(loadContractDetails(instance));
			}

			// request all contracts info
			const details = await Promise.all(resolver);
			const contractInfos = {};
			contractNames.forEach((name, i) => {
				contractInfos[name] = details[i];
			});
			// Load contract info in the store
			commit("setContracts", contractInfos);
		}
	},
};
