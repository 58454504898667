/* eslint-disable */
import {
  MSGS,
  EVENT_CHANNEL,
  connect,
  emitter,
  ready,
  getProvider,
  getWallet,
  getWalletAddress,
  getNetName,
  hasEns,
} from "../../lib/ethersConnect";

export const state = () => ({
  initialized: false,
  connected: false,
  error: null,
  // user is ens or address
  user: "",
  address: "",
  network: "",
  ens: null,
});

export const mutations = {
  initialized: function (state, value) {
    state.initialized = value;
  },
  connected: function (state, value) {
    state.connected = value;
  },
  error: function (state, value) {
    state.error = value;
  },
  user: function (state, value) {
    state.user = value;
  },
  address: function (state, value) {
    state.address = value;
  },
  network: function (state, value) {
    state.network = value;
  },
  ens: function (state, value) {
    state.ens = value;
  },
};

export const getters = {};

export const actions = {
  async connect(ctx) {
    try {
      const oldAddress = ctx.state.address;
      const oldNetwork = ctx.state.network;

      const provider = getProvider();
      if (!provider) throw new Error(MSGS.NOT_CONNECTED);

      const wallet = getWallet();
      if (!wallet) throw new Error(MSGS.NO_WALLET);
      const address = await getWalletAddress();
      const network = await getNetName();

      if (network !== oldNetwork || address !== oldAddress) {
        ctx.commit("connected", true);
        ctx.commit("error", null);
        ctx.commit("address", address);
        ctx.commit("user", address);
        ctx.commit("network", network);

        const msg =
          oldAddress && oldAddress !== address
            ? `Your Ethereum address/user has changed.
          Address: ${address}
          Network: ${network}
          Gas Price: ${await provider.getGasPrice()}
          Current Block #: ${await provider.getBlockNumber()}
          Your ether balance: ${await provider.getBalance(address)}`
            : `You are connected to the Ethereum Network.
          Address: ${address}
          Network: ${network}
          Gas Price: ${await provider.getGasPrice()}
          Current Block #: ${await provider.getBlockNumber()}
          Your ether balance: ${await provider.getBalance(address)}
          If you change your address or network, this app will update automatically.`;
        console.log(msg);

        // Other vuex stores can wait for this
        //emitter.emit(EVENT_CHANNEL, MSGS.ETHERS_VUEX_READY)

        // now check for .eth address too
        if (await hasEns()) {
          console.log("Net supports ENS. Checking...");
          ctx.commit("ens", await provider.lookupAddress(address));
          if (ctx.state.ens) {
            ctx.commit("user", ens);
          }
        }

        provider.on("block", (blockNumber) => {
          console.log("Block mined:", blockNumber);
        });
      }
    } catch (err) {
      ctx.dispatch("disconnect", err);
    }
  },
  async disconnect(ctx, err) {
    const oldAddress = ctx.state.address;
    ctx.commit("connected", false);
    ctx.commit("error", err);
    ctx.commit("address", "");
    ctx.commit("user", "");
    ctx.commit("network", "");
    ctx.commit("ens", null);
  },
  async logout(ctx) {
    ctx.commit("address", "");
    ctx.commit("user", "");
  },
  async notConnected(ctx) {
    ctx.commit("address", "");
    ctx.commit("user", "");
  },
  async init(ctx) {
    /*emitter.on(EVENT_CHANNEL, async function (msg) {
      console.log('Ethers event received', msg)
      switch (msg) {
        case MSGS.NOT_READY:
          await ctx.dispatch('disconnect')
          break
        case MSGS.NO_WALLET:
          await ctx.dispatch('logout')
          break
        case MSGS.ACCOUNT_CHANGED:
          await ctx.dispatch('connect')
          break
        case MSGS.NOT_CONNECTED:
          await ctx.dispatch('notConnected')
          break
      }
    })*/

    if (ready()) {
      await ctx.dispatch("connect");
      //emitter.emit(EVENT_CHANNEL, MSGS.ETHERS_VUEX_INITIALIZED);
    } else {
      console.log("Log in to your Ethereum wallet to see what it can do!");
      await connect();
      await ctx.dispatch('connect')
     
    }
    ctx.commit("initialized", true);
  },
};
