/* eslint-disable */

export const state = () => ({
	shippings: [],
	active: {},
});

export const mutations = {
	addShipping: function (state, value) {
		state.shippings.push({
			shipping: state.shippings.length + 1,
			next_shipping: value.next_shipping,
			deadline: value.deadline,
			active: value.active,
		});
	},
	removeShipping: function (state, value) {
		let toDelete = state.shippings.findIndex((e) => e.shipping == value);
		delete state.shippings[toDelete];
	},
	setActive: function (state, value) {
		let active = state.shippings.findIndex((e) => e.shipping == value);
		for (let i = 0; i < state.shippings.length; i++) {
			state.shippings[i].active = i == active;
		}
	},
	setVisible: function (state, value) {
		let active = state.shippings.findIndex((e) => e.shipping == value);
		for (let i = 0; i < state.shippings.length; i++) {
			state.shippings[i].active = i == active;
		}
		state.active = state.shippings[active];
	},
	initShipment: function (state, value) {
		state.shippings = value.shippings;
		state.active = value.active;
	},
};

export const getters = {};

export const actions = {};
